/* You can add global styles to this file, and also import other style files */
$sgbs-font-path: '~@sg-bootstrap/core/fonts';

@import '@sg-bootstrap/icons/dist/index.min.css';
@import '@sg-bootstrap/core/scss/sg-bootstrap-standard';

.ng-touched.ng-invalid {
  @extend .is-invalid;
}

.modal-dialog-details {
  position: absolute !important;
  top: 0;
  right: 0;
  margin: 0 !important;
  height: 100%;

  @media (min-width: 576px) {
    width: 576px !important;
  }
  @media (max-width: 576px) {
    width: 100% !important;
  }
}

.modal-content {
  max-height: 100vh;
}

.modal-content-details {
  height: 100%;
}

.modal-body-details {
  max-height: 100% !important;
}

.modal-footer-details {
  position: absolute;
  bottom: 0;
}

.toast-top-right {
  top: 1.5rem !important;
  right: 1.5rem !important;
}
